<template>
  <div>
    <VSimpleTable class="elevation-2">
      <thead>
        <tr class="titles-thead">
          <th class="text-left city-column">
            <span>Город</span>
          </th>
          <th class="text-left">
            <span>Название заведения</span>
          </th>
          <th class="text-left">
            <span>Категории</span>
          </th>
          <th class="text-left">
            <span>Хабы</span>
          </th>
          <th class="text-center delivery-service-column">
            <span>Служба доставки</span>
          </th>
          <th class="text-center">
            <span>Статус</span>
          </th>
          <th class="text-center">
            <span>Менеджер</span>
          </th>
          <th></th>
        </tr>
        <tr class="search-thead">
          <th>
            <VSelect
              v-model="restaurantFilter.cityTitle"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              item-text="title"
              item-value="title"
              :items="cities"
              label="Все"
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
            />
          </th>
          <th>
            <VTextField
              v-model="restaurantFilter.restaurantTitle"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
            />
          </th>
          <th>
            <VSelect
              v-model="restaurantFilter.category_ids"
              background-color="#5B5B5B"
              class="cities__select"
              clearable
              dark
              dense
              hide-details
              item-text="title"
              item-value="id"
              :items="categories"
              label="Все"
              multiple
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
            >
              <template #selection="{ item, index }">
                <span v-if="index < 1">
                  {{ item.title }}
                </span>
                <span v-if="index === 1" class="pl-1">
                  + {{ restaurantFilter.category_ids.length - 1 }}
                </span>
              </template>
            </VSelect>
          </th>
          <th>
            <VSelect
              v-model="restaurantFilter.hasHubs"
              background-color="#5B5B5B"
              dark
              dense
              hide-details
              item-text="title"
              item-value="value"
              :items="hubs"
              label="Все"
              outlined
              solo
            />
          </th>
          <th>
            <VSelect
              v-model="restaurantFilter.deliveryService"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              :items="deliveryChoices"
              label="Все"
              outlined
              solo
            />
          </th>
          <th>
            <VSelect
              v-model="restaurantFilter.restaurantState"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              :items="restaurantStates"
              label="Все"
              outlined
              solo
            />
          </th>
          <th>
            <VTextField
              v-model="restaurantFilter.managerEmail"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-account-circle-outline"
              solo
            />
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(restaurant, index) in restaurants"
          :key="index"
          :class="{
            'red lighten-4': restaurant.activity==='inProcess',
            'grey lighten-2': restaurant.activity==='inactive'
          }"
        >
          <td><span>{{ getCityTitleByLocation(restaurant.location.coordinate) }}</span></td>
          <td><span>{{ restaurant.title }}</span></td>
          <td class="pt-1 my-0">
            <ChipGroup
              v-if="restaurant.categories.length"
              back-color="#F4F6FB"
              :has-remove="false"
              :items="restaurant.categories"
              :max="2"
              truncate
            />
          </td>
          <td class="pt-1 my-0">
            <ChipGroup
              v-if="restaurant.hubs.length"
              back-color="#F4F6FB"
              :has-remove="false"
              :items="restaurant.hubs"
              :max="2"
              truncate
            />
          </td>
          <td class="text-center">
            <span>{{ restaurant.deliveryService }}</span>
          </td>
          <td
            class="text-center"
            :class="{
              'green--text': restaurant.activity==='active',
              'grey--text': restaurant.activity==='inactive'
            }"
          >
            {{ restaurant.stateTitle }}
          </td>
          <td>
            <span>
              {{ restaurant.managerEmail }}
            </span>
          </td>
          <td class="text-center">
            <RouterLink
              :to="{
                name: 'edit_restaurant',
                params: { pk: restaurant.pk }
              }"
            >
              <VIcon color="black" large>
                mdi-chevron-right
              </VIcon>
            </RouterLink>
          </td>
        </tr>
      </tbody>
    </VSimpleTable>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fetchCategories, fetchRestaurantChoices } from '@/api/api';
import { restaurantStates } from '@/conditions/states';
import ChipGroup from '@/components/reusable/ChipGroup.vue';

export default {
  props: {
    restaurants: {
      type: Array,
      default() {
        return [];
      },
    },
    filterTitle: {
      type: String,
      default: () => '',
    },
  },
  components: {
    ChipGroup,
  },
  emits: ['filterChanged'],
  computed: {
    ...mapGetters(['cities']),
  },
  methods: {
    ...mapActions(['getCities']),
    searchBy() {
      this.$emit('filterChanged', this.restaurantFilter);
    },
    getCityTitleByLocation(location) {
      return this.cities.reduce((init, city) => {
        const contains = city.containsPoint(location.longitude, location.latitude);
        return contains ? city.title : init;
      }, null);
    },
  },
  data() {
    return {
      deliveryChoices: [],
      restaurantStates,
      restaurantFilter: {
        cityTitle: '',
        restaurantTitle: '',
        deliveryService: '',
        restaurantState: '',
        managerEmail: '',
        hasHubs: '',
        category_ids: [],
      },
      categories: [],
      hubs: [
        { title: 'Все', value: '' },
        { title: 'С метками', value: 'true' },
        { title: 'Без меток', value: 'false' },
      ],
    };
  },
  created() {
    this.getCities();
    fetchRestaurantChoices().then((choices) => {
      this.deliveryChoices = choices.deliveryChoices;
    });
    fetchCategories()
      .then(
        (res) => {
          console.log(res);
          this.categories = res;
        },
      );
  },
  watch: {
    filterTitle(newSearch = '') {
      this.restaurantFilter.restaurantTitle = newSearch;
      this.searchBy();
    },
    restaurantFilter: {
      deep: true,
      handler() {
        this.searchBy();
      },
    },
  },
};
</script>

<style scoped lang='scss'>
@import 'src/assets/scss/mixin.scss';

.titles-thead th {
  color: $default_dark_gray_color !important;
  background-color: white;
}
.search-thead th {
  color: white !important;
  background-color: $default_gray_color;
}
a {
  text-decoration: none;
}
.restaurant_active {
  .state div {
    color: #4CAF50;
  }
}
.restaurant_inactive {
  background-color: #FFD4D4;
}
.city-column {
  width: 250px;
}
.delivery-service-column {
  width: 200px;
}
</style>
